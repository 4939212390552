import { getRandomInt, shuffleArray } from "./utils";

const getTime = () => {
  const minutes = `${getRandomInt(0, 59)}`.padStart(2, "0");

  return `${getRandomInt(1, 20)}:${minutes}`;
};

const getViews = () => {
  return document.createTextNode(` ${getRandomInt(10, 999)}K`);
};

const getLikes = () => {
  return document.createTextNode(` ${getRandomInt(70, 99)}%`);
};

const init = () => {
  const memesCount = 31;
  const videosCount = 64;

  const previews = [];

  for (let id = 1; id <= memesCount; id++) {
    previews.push({
      type: "meme",
      id,
    });
  }

  for (let id = 1; id <= videosCount; id++) {
    previews.push({
      type: "video",
      id,
    });
  }

  shuffleArray(previews);

  const previewsContainer = document.querySelector(".previews");
  const previewTemplate = document.getElementById("preview-template");

  if (previewsContainer) {
    previews.forEach((preview) => {
      const url = `/content.html?type=${preview.type}&id=${preview.id}`;
      const imageExt = preview.type === "meme" ? "png" : "jpg";
      const imageSrc = `/static/previews/${preview.type}-${preview.id}.${imageExt}`;

      const clone = previewTemplate.content.cloneNode(true);
      clone.querySelector(".preview__image").src = imageSrc;
      clone.querySelector(".preview__time").textContent = getTime();
      clone.querySelectorAll("a").forEach((item) => {
        item.href = url;
      });
      clone.querySelector(".preview__param_views").appendChild(getViews());
      clone.querySelector(".preview__param_likes").appendChild(getLikes());

      previewsContainer.insertAdjacentElement(
        "afterbegin",
        clone.firstElementChild
      );
    });
  }
};

export default { init };
