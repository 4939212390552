import MicroModal from "micromodal";

const init = () => {
  const isShown = sessionStorage.getItem("modal-1-shown");

  if (isShown) {
    return;
  }

  setTimeout(() => {
    MicroModal.show("modal-1");
    sessionStorage.setItem("modal-1-shown", "yes");
  }, 10000);
};

export default { init };
