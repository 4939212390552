const init = () => {
  const params = new URLSearchParams(document.location.search);

  const id = params.get("id");
  const type = params.get("type");

  if (!id || !type) {
    return;
  }

  const container = document.querySelector(".content__main");
  const playerTemplate = document.getElementById("player-template");

  if (!container || !playerTemplate) {
    return;
  }

  const playerElement = playerTemplate.content.cloneNode(true);
  const playerVideo = playerElement.querySelector(
    ".content__player-video source"
  );
  const playerImage = playerElement.querySelector(".content__player-meme img");

  if (type === "meme") {
    playerImage.src = `/static/content/${type}-${id}.png`;
    playerVideo.parentElement.remove();
  } else if (type === "video") {
    playerVideo.src = `/static/content/${type}-${id}.mp4`;
    playerImage.parentElement.remove();

    playerVideo.parentElement.volume =
      localStorage.getItem("last-volume") || 0.5;
    playerVideo.parentElement.onvolumechange = (event) => {
      localStorage.setItem("last-volume", event.target.volume);
    };
  }

  container.insertAdjacentElement(
    "afterbegin",
    playerElement.firstElementChild
  );
};

export default { init };
