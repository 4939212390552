import tags from "./js/tags";
import modal from "./js/modal";
import player from "./js/player";
import banners from "./js/banners";
import previews from "./js/previews";

document.addEventListener("DOMContentLoaded", () => {
  tags.init();
  banners.init();
  previews.init();

  player.init();
  modal.init();
});
