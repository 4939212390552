import { getRandomInt } from "./utils";

const init = () => {
  const bannerImage = document.querySelector(".banner_box > img");

  if (bannerImage) {
    bannerImage.src = `/static/banners/${getRandomInt(1, 4)}.gif`;
    bannerImage.parentElement.hidden = false;
  }
};

export default { init };
