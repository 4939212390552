import { shuffleArray } from "./utils";

const init = () => {
  const items = [
    "гей-порно",
    "Рай",
    "фистинг",
    "сиалис",
    "опытная женщина",
    "Рай №2",
    "Учи математику или здохни",
    "АСМР",
    "проект восстание",
    "настоящая сила",
  ];

  shuffleArray(items);

  const tagsContainer = document.querySelector(".tags__items");

  if (tagsContainer) {
    items.forEach((tag) => {
      const el = document.createElement("a");
      el.href = "/";
      el.className = "tags__item";
      el.textContent = tag;
      tagsContainer.appendChild(el);
    });
  }
};

export default { init };
